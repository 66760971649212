import * as React from "react";
import { Box, Button } from "theme-ui";

import Container from "components/layout/Container";
import Wrapper from "components/layout/Wrapper";
import BackToTopIcon from "images/icons/back-to-top.svg";
import PropTypes from "lib/propTypes";
import useScrollTo from "lib/useScrollTo";

function BackToTop({ onClick, variant }) {
  const { scrollTo } = useScrollTo();

  const overlay = variant === `overlay`;

  const scrollToTop = () => {
    scrollTo(0);
    onClick();
  };

  return (
    <Wrapper
      as="div"
      py={0}
      bg={variant === `yellow` ? `whirlpoolYellow` : `transparent`}
      sx={{
        position: `relative`,
        height: overlay ? 0 : null,
      }}
    >
      <Box pb={[`m`, `l`]}>
        <Container
          sx={{
            display: `flex`,
            justifyContent: `flex-end`,
          }}
        >
          <Button
            aria-label="Back to Top"
            variant={`backToTop${overlay ? `Light` : `Dark`}`}
            sx={{
              position: overlay ? `absolute` : `static`,
              right: [`m`, `l`],
              bottom: [`m`, `l`],
            }}
            onClick={scrollToTop}
          >
            <BackToTopIcon />
          </Button>
        </Container>
      </Box>
    </Wrapper>
  );
}

BackToTop.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([`overlay`, `transparent`, `yellow`]),
};

BackToTop.defaultProps = {
  onClick: () => {},
  variant: `overlay`,
};

export default BackToTop;
