import BezierEasing from "bezier-easing";
import useScrollTo from "react-spring-scroll-to-hook";

const COLLAPSE_DURATION = 1000;

export default (props) => ({
  duration: props?.duration ?? COLLAPSE_DURATION,
  ...useScrollTo({
    duration: COLLAPSE_DURATION,
    easing: BezierEasing(0.4, 0, 0.2, 1),
    ...props,
  }),
});
