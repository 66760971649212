import * as React from "react";
import { Box } from "theme-ui";
import { useTransition, animated } from "@react-spring/web";
import { useKey } from "react-use";

import Bar from "components/global/Header/Bar";
import PropTypes from "lib/propTypes";

const AnimatedBox = animated(Box);

function Modal({ bordered, children, close, logo, logoIcon, visible }) {
  useKey(`Escape`, close);

  const containerTransitions = useTransition(visible, {
    from: { outerY: `-100%`, innerY: `100%` },
    enter: { outerY: `0`, innerY: `0` },
    leave: { outerY: `100%`, innerY: `-100%` },
  });

  return containerTransitions(
    ({ innerY, outerY }, item) =>
      item && (
        <AnimatedBox
          sx={{
            position: `fixed`,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 10,
            overflow: `hidden`,
            bg: `black`,
            color: `white`,
          }}
          style={{ y: outerY }}
        >
          <AnimatedBox
            sx={{
              display: `flex`,
              flexDirection: `column`,
              position: `absolute`,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              borderBottom: bordered ? `solid` : null,
              borderWidth: `xs`,
              borderColor: `whirlpoolYellow`,
              overflowX: `hidden`,
            }}
            style={{ y: innerY }}
          >
            <Bar {...{ close, logo, logoIcon }} />
            {children}
          </AnimatedBox>
        </AnimatedBox>
      )
  );
}

Modal.propTypes = {
  bordered: PropTypes.bool,
  children: PropTypes.node,
  close: PropTypes.func.isRequired,
  logo: PropTypes.asset,
  logoIcon: PropTypes.asset,
  visible: PropTypes.bool,
};

Modal.defaultProps = {
  border: false,
  children: null,
  logo: {},
  logoIcon: {},
  visible: true,
};

export default Modal;
